import parse from "html-react-parser";
import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import whiteLabelInformation from "../../../../util/whitelabel";
import "./MobileHeaderText.css";

const MobileHeaderText = (): ReactElement => {
  const { state } = useAppContext();
  const { sending, recording } = state;

  const {
    mobile: { sendingTitle, recordingTitle, waitingTitle },
  } = whiteLabelInformation;

  // Sets the text in the component based on the state.
  if (sending) {
    return (
      <div className="MobileHeaderText">
        <span>{parse(sendingTitle)}</span>
      </div>
    );
  }
  if (recording) {
    return (
      <div className="MobileHeaderText">
        <span>{parse(recordingTitle)}</span>
      </div>
    );
  }
  return (
    <div className="MobileHeaderText">
      <span>{parse(waitingTitle)}</span>
    </div>
  );
};

export default MobileHeaderText;
