import { library } from "@fortawesome/fontawesome-svg-core";
import * as IconsB from "@fortawesome/free-brands-svg-icons";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { disableBodyScroll } from "body-scroll-lock";
import React, { ReactElement, useEffect } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import useAppContext from "../../util/helper";
import SettingsModal from "../MainPage/SettingsModal/SettingsModal";
import MobileActionsList from "../MobileComponents/MobileActionsList/MobileActionsList";
import MobileBottomBar from "../MobileComponents/MobileBottomBar/MobileBottomBar";
import MobileTutorialModal from "../MobileComponents/MobileBottomBar/MobileTutorialModal/MobileTutorialModal";
import MobileContactModal from "../MobileComponents/MobileContactModal/MobileContactModal";
import MobileMenu from "../MobileComponents/MobileMenu/MobileMenu";
import MobileTopBar from "../MobileComponents/MobileTopBar/MobileTopBar";
import ContactBox from "../WhiteLabelComponents/ContactBox/ContactBox";
import LogoComponent from "../WhiteLabelComponents/LogoComponent/LogoComponent";
import PrivacyComponent from "../WhiteLabelComponents/PrivacyComponent/PrivacyComponent";
import PrivacyModal from "../WhiteLabelComponents/PrivacyModal/PrivacyModal";
import ActionsList from "./ActionsList/ActionsList";
import DigitalHuman from "./Avatar/DigitalHuman/DigitalHuman";
import ButtonsComponent from "./ButtonsComponent/ButtonsComponent";
import CloseModal from "./CloseModal/CloseModal";
import ErrorModal from "./ErrorModal/ErrorModal";
import LastUtterance from "./LastUtterance/LastUtterance";
import LoadingComponent from "./LoadingComponent/LoadingComponent";
import "./MainPage.css";
import "./Modal.css";
import SpaceBar from "./SpaceBar/SpaceBar";
import SpaceBarTutorial from "./SpaceBarTutorial/SpaceBarTutorial";

const MainPage = (): ReactElement => {
  const { state } = useAppContext();
  const { actionListResponses, spaceBarTutorialVisible } = state;

  const iconList = Object.keys(Icons)
    .filter((key) => key !== "fas" && key !== "prefix")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((icon) => (Icons as { [key: string]: any })[icon]);

  library.add(...iconList);

  const iconListBrand = Object.keys(IconsB)
    .filter((key) => key !== "fab" && key !== "prefix")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((iconb) => (IconsB as { [key: string]: any })[iconb]);

  library.add(...iconListBrand);

  // Prevent scrolling mobile website, hiding white space bottom.
  const targetElement = document.querySelector("body");
  useEffect(() => {
    if (isMobile) {
      window.scroll(0, 0);
      disableBodyScroll(targetElement!);
    }
  });

  return (
    <div>
      <DigitalHuman />
      <LoadingComponent />

      <div className="containerMain">
        {/* First row  */}
        {isBrowser && (
          <div className="firstRow">
            {actionListResponses.length > 0 ? <ActionsList /> : <div />}
            <div>
              <ContactBox />
              <ButtonsComponent />
            </div>
          </div>
        )}
        {isMobile && (
          <div className="firstRowMobile">
            <MobileTopBar />
          </div>
        )}

        {/* Input row */}
        {isBrowser && (
          <div className="inputRow">
            <SpaceBar />
            <LastUtterance />
            {spaceBarTutorialVisible && <SpaceBarTutorial />}
          </div>
        )}

        {isMobile && (
          <div className="inputRowMobile">
            <MobileActionsList />
          </div>
        )}

        {/* Last row */}
        {isBrowser && (
          <div className="lastRow">
            <PrivacyComponent />
            <LogoComponent />
          </div>
        )}
        {isMobile && (
          <div className="lastRowMobile">
            <MobileBottomBar />
          </div>
        )}
        <CloseModal />
        <ErrorModal />
        <PrivacyModal />
        <SettingsModal />
        {isMobile && (
          <>
            <MobileMenu /> <MobileContactModal /> <MobileTutorialModal />
          </>
        )}
      </div>
    </div>
  );
};

export default MainPage;
