import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import { ActionListHeader, ActionListResponse, ActionListResponseLink } from "../../../util/state/initialState";
import whiteLabelInformation from "../../../util/whitelabel";
import "./ActionsList.css";
import SuggestedLink from "./SuggestedLink/SuggestedLink";
import SuggestedQuestion from "./SuggestedQuestion/SuggestedQuestion";

const ActionsList = (): ReactElement => {
  const { state } = useAppContext();
  const { actionListResponses, ready } = state;
  const {
    actionList: { defaultHeaderTitle },
  } = whiteLabelInformation;
  let headerTitle;
  const JSONActionHeader = actionListResponses.find((action: ActionListHeader) => action.type === "header-text");
  // Check if the JSONActionHeader is defined, and it's label is not an empty or witespacce filled string.
  if (JSONActionHeader !== undefined && JSONActionHeader.label.trim() !== "") {
    // Set the Header to the header supplied in the JSON
    headerTitle = JSONActionHeader.label;
  } else {
    // Set the headerTitle to the one supplied in the whitelabel
    headerTitle = defaultHeaderTitle;
  }
  return (
    <div className={classNames("ActionsList", { "ActionsList hidden": !ready })}>
      <div className="ActionsListHeader">
        <span>{headerTitle}</span>
      </div>
      {actionListResponses.slice(0, 4).map((action: ActionListResponse) => {
        if (action.type === "suggested-link") {
          const actionLink = action as ActionListResponseLink;
          return (
            <SuggestedLink
              key={actionLink.label}
              label={actionLink.label}
              url={actionLink.url}
              alt={actionLink?.alt}
              highlighted={actionLink.highlighted}
              iconName={actionLink["icon-name"]}
              iconPrefix={actionLink["icon-prefix"]}
            />
          );
        }
        if (action.type === "suggested-question") {
          const actionQuestion = action as ActionListResponse;
          return (
            <SuggestedQuestion
              key={actionQuestion.label}
              label={actionQuestion.label}
              highlighted={actionQuestion.highlighted}
              overrideBackgroundColorHex={actionQuestion["override-font-color-hex"]}
              overrideFontColorHex={actionQuestion["override-font-color-hex"]}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default ActionsList;
