import classNames from "classnames";
import React, { ReactElement, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { Uneeq } from "uneeq-js";
import useAppContext from "../../../../util/helper";
import { ActionType } from "../../../../util/state/reducer";
import whiteLabelInformation from "../../../../util/whitelabel";
import UneeqVideo from "../UneeqVideo/UneeqVideo";
import "./DigitalHuman.css";

const DigitalHuman = (): ReactElement => {
  const { state, dispatch, refs, uneeq } = useAppContext();
  const messageHandler = useRef(dispatch); // Puts dispatch into a ref, so we can turn it into a no-op to prevent the Uneeq SDK from calling it after unmount.

  const { timeoutMessageVisible, ready, sessionEnded, timedOut, closeModalVisible } = state;
  const {
    closeModal: { timeoutTitle, timeoutSubtitle, timeoutBody },
  } = whiteLabelInformation;

  /**
   * Timeout functionality - reduces the timeLeft state every second. Used to determine whether the session is timed-out or not.
   */
  useEffect(() => {
    const id = setInterval(() => {
      dispatch({ type: ActionType.COUNTER_TICK });
    }, 1000);
    return () => clearInterval(id);
  });

  /**
   * Timeout functionality - Triggers showing the timeout message and ending session when no time is left.
   */
  useEffect(() => {
    if ((ready || sessionEnded) && timeoutMessageVisible && !closeModalVisible) {
      dispatch({
        type: ActionType.SHOW_CLOSE_MODAL,
        payload: {
          closeModalTitle: timeoutTitle,
          closeModalBody: timeoutBody,
          closeModalSubtitle: timeoutSubtitle,
          closeModalHasTimer: true,
        },
      });
    }
    if ((ready || sessionEnded) && timedOut) {
      uneeq.current.endSession();
    }
  }, [
    timeoutMessageVisible,
    timedOut,
    timeoutTitle,
    timeoutSubtitle,
    timeoutBody,
    closeModalVisible,
    dispatch,
    ready,
    sessionEnded,
    uneeq,
  ]);

  /**
   * When both avatarVideo and localVideo are defined, initializes a new instance of the Uneeq class.
   * After doing so, requests permission and initializes the session.
   */
  useEffect(() => {
    /**
     * Firstly fetches the Uneeq token, then initialises the session with Uneeq.
     * @returns {Promise<void>}
     */
    const initializeUneeqSession = async (): Promise<void> => {
      try {
        const response = await fetch(process.env.REACT_APP_UNEEQ_TOKEN_URL!);
        if (response.ok) {
          const { token } = await response.json();
          uneeq.current.initWithToken(token);
        } else {
          throw new Error(`Error fetching token, HTTP code: ${response.status}`);
        }
      } catch (error) {
        console.error(
          `[initializeUneeqSession] Encountered the following error while initializing Uneeq session: ${error}`,
        );
      }
    };

    /**
     * Creates the keyboard event listeners to trigger recording on spacedown.
     */
    const createRecordingKeyListeners = (): void => {
      // When keydown on Spacebar is dispatch an event to the reducer and set the UneeqSDK to start recording
      window.onkeydown = (e: KeyboardEvent) => {
        if (e.code === "Space" && !e.repeat) {
          dispatch({ type: ActionType.START_RECORDING });
          uneeq?.current?.startRecording();
        }
      };
      // When keyup on Spacebar is dispatch an event to the reducer and set the UneeqSDK to stop recording and start sending.
      window.onkeyup = (e: KeyboardEvent) => {
        if (e.code === "Space" && !e.repeat) {
          dispatch({ type: ActionType.STOP_RECORDING });
          dispatch({ type: ActionType.START_SENDING });
          uneeq.current.stopRecording();
        }
      };
    };

    if (refs.avatarVideo && refs.localVideo) {
      uneeq.current = new Uneeq({
        playWelcome: true,
        url: process.env.REACT_APP_UNEEQ_URL!,
        conversationId: process.env.REACT_APP_UNEEQ_CONVERSATION_ID!,
        messageHandler: (msg) => messageHandler.current({ type: "uneeqMessage", payload: msg }),
        avatarVideoContainerElement: refs.avatarVideo,
        localVideoContainerElement: refs.localVideo,
        sendLocalVideo: false,
        voiceInputMode: "PUSH_TO_TALK",
      });

      initializeUneeqSession();
      createRecordingKeyListeners();

      return () => {
        // Return cleanup, the handler is a no-op, so dispatch is not called after unmount.
        messageHandler.current = () => {};
        uneeq.current.endSession();
      };
    }

    return () => {};
  }, [refs.avatarVideo, refs.localVideo, dispatch, uneeq]);

  return (
    <div className="DigitalHuman">
      <UneeqVideo
        className={classNames({ UneeqVideo: !isMobile, UneeqVideoMobile: isMobile })}
        refName="setAvatarVideo"
      />
      {/* Component below, localVideo, is not used and therefore hidden, but can't be deleted as the Uneeq SDK expects it. */}
      <UneeqVideo className="UneeqVideo UneeqVideoHidden" refName="setLocalVideo" />
    </div>
  );
};

export default DigitalHuman;
