import classNames from "classnames";
import React, { ReactElement } from "react";
import earIcon from "../../../assets/img/icon-ear.svg";
import useAppContext from "../../../util/helper";
import "./LastUtterance.css";

const LastUtterance = (): ReactElement => {
  const { state } = useAppContext();
  const { lastUtterance, ready } = state;

  return (
    <div className={classNames("LastUtterance", { "LastUtterance hidden": !ready })}>
      <img src={earIcon} alt="Ear icon" />
      <span>{lastUtterance}</span>
    </div>
  );
};

export default LastUtterance;
