import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import "./MobileBottomBar.css";
import MobileSpeakButton from "./MobileSpeakButton/MobileSpeakButton";
import { ReactComponent as BottomCurve } from "../../../assets/img/mobile-bottom-bar-curve.svg";
import MobileExplainerButton from "./MobileExplainerButton/MobileExplainerButton";
import whiteLabelInformation from "../../../util/whitelabel";

const MobileBottomBar = (): ReactElement => {
  const { state } = useAppContext();
  const { ready } = state;

  const {
    mobileTutorial: { title, body },
  } = whiteLabelInformation;

  const showExplainerButton = title && body;

  return (
    <div className={classNames("MobileBottomBar", { "MobileBottomBar hidden": !ready })}>
      <div className="MobileBottomStroke" />
      <BottomCurve className="BottomCurve" />
      <MobileSpeakButton />
      {showExplainerButton && <MobileExplainerButton />}
    </div>
  );
};

export default MobileBottomBar;
